import React, {Component} from 'react'
import injectSheet from 'react-jss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import HeaderText from './header-text'
import Button from './button'

const styles = {
  minicard: {
    display: 'flex',
    flexFlow: 'column',
    width: 'calc(33.33vw - 3em)',
    backgroundColor: 'white',
    borderTop: '8px solid #F37B1D',
    minWidth: '160px',
    margin: '1em',
    padding: '1em',
    '@media (max-width: 1080px)': {
      width: 'calc(50vw - 3em)'
    },
    '@media (max-width: 767px)': {
      width: 'calc(100vw - 4em)'
    },
    '@media (min-width: 768px)': {
      maxWidth: '420px'
    }
  },
  image: {
    width: 'calc(33.33vw - 5em)',
    height: 'calc(33.33vw - 5em)',
    backgroundSize: 'cover',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
    marginBottom: '1em',
    minWidth: '128px',
    minHeight: '128px',
    '@media (max-width: 1080px)': {
      width: 'calc(50vw - 5em)',
      height: 'calc(50vw - 5em)'
    },
    '@media (max-width: 767px)': {
      width: 'calc(100vw - 7em)',
      height: 'calc(100vw - 6em)',
    },
    '@media (min-width: 768px)': {
      maxWidth: 'calc(420px - 2em)',
      maxHeight: 'calc(420px - 2em)',
    }
  },
  description: {
    color: 'black',
    flexGrow: '1'
  }
}

class MiniCard extends Component {
  render() {
    const {classes, image, video, title, description, children, button, btnOnClick} = this.props
    return(
      <div className={classes.minicard}>
        {image && <div className={classes.image} style={{backgroundImage: 'url(' + image + ')'}}/>}
        {video && <iframe title={video} className={classes.image} src={`https://www.youtube.com/embed/${video}`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>}
        <HeaderText dark small>{title}</HeaderText>
        <h4 className={classes.description}>{description||children}</h4>
        {button && <div><br/><Button dark onClick={btnOnClick}>{button} <span>&nbsp;&nbsp;<FontAwesomeIcon icon="chevron-right"/></span></Button></div>}
      </div>
    )
  }
}

export default injectSheet(styles)(MiniCard)

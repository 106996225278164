import React from 'react'
import {navigateTo} from 'gatsby-link'

import Layout from '../components/layout'
import JumboBackground from '../components/jumbo-background'
import CardBox from '../components/card-box'
import MiniCard from '../components/mini-card'

import Russell from '../images/russellst/094dark.jpg'
import Armadale from '../images/armadalest/n6.jpg'
import Eskdale from '../images/eskdalerd/5.jpg'
import Aitken from '../images/aitken/2.jpg'
import Courthouse from '../images/courthouse.jpg'
import Tram from '../images/tram_relocation.jpg'
import Outside from '../images/outside.jpg'


const ProjectsPage = ({location}) => {
	return (
    <Layout path={location.pathname}>

      <JumboBackground
        background={Eskdale}
			/>

      <CardBox
        title="Eskdale Road, Caulfield North"
        button="View photos"
        noSpacer
        btnArrow
        btnOnClick={() => navigateTo('/eskdale-road')}
			>
        <MiniCard
          image={Armadale}
          title="Armadale Street, Armadale"
          button="View photos"
          btnOnClick={() => navigateTo('/armadale-street')}
        />
        <MiniCard
          image={Outside}
          title="Nelson Street, Abbotsford"
          button="View photos"
          btnOnClick={() => navigateTo('/nelson-street')}
        >
        A former single level warehouse, this site was transformed into a brilliant 'aged' two storey office space.
        <br/><br/>
        This is building No. 1 in the Rock Art Series, watch out for Building No. 2 as it comes to life just around the corner in Russell St!
        </MiniCard>
        <MiniCard
          image={Russell}
          title="Russell Street, Abbotsford"
          button="View photos"
          btnOnClick={() => navigateTo('/russell-street')}
          ></MiniCard>
        <MiniCard
          image={Aitken}
          title="Aitken College"
          button="View photos"
          btnOnClick={() => navigateTo('/aitken-college')}
        >
        </MiniCard>
        <MiniCard
          image={Tram}
          title="Tram relocation"
        >
          The recent ballot of W Class Trams saw one of our clients take possession of this beauty!
          <br/><br/>
          Our involvement included Structural Design of new foundations, Relocation of the Tram from the Newport Yard to Nunawading, Civil works associated with making good the roadway adjacent. Seating removal, Asbestos removal & Flooring replacement.
          <br/><br/>
          Many thanks to all the staff at Bendigo Tramways for their invaluable advice, technical expertise and support during this project.
        </MiniCard>
        <MiniCard
          image={Courthouse}
          title="Former Essendon Courthouse"
        >
          Mt Alexander Rd, Moonee Ponds
          <br/><br/>
          After the tragedy of the fire that saw the beautiful Courthouse largely destroyed, our tradesmen had the privledge of carrying out the restoration of the roof and upper level windows to enable the building to be watertight once more.
          <br/><br/>
          We'll look on with admiration as another team of tradesmen now take on the internal restoration of this project.
        </MiniCard>
        <MiniCard
          video="rYKd5wf-cYQ"
          title="Monument Men"
        >
          Relocation of this monument allowed Vic Roads to widen the Princess Highway at Traralgon.
        </MiniCard>
        <MiniCard
          video="ck43zJNpS-s"
          title="Myoora Motor House"
        >
          Clear span, structural steel frame designed to replicate the historic stables long since removed from this very special property.
        </MiniCard>
      </CardBox>

    </Layout>
  )
}

export default ProjectsPage
